import { useMsal } from "@azure/msal-react";
import {
  Button,
  Typography,
} from '@mui/material';
import { loginRequest } from "../authConfig";
import gateway from "../images/gateway-logo.png";


export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect({
            ...loginRequest,
            redirectUri: process.env.REACT_APP_POPUP_REDIRECT_URI,
        });
    };

    return (
        <div>
            <Button
                onClick={handleLogin}
                variant="contained"
                style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "20px", // Rounded corners 
                    transition: "transform 0.2s", // Add hover effect
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.transform = "scale(1.05)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <img
                      src={gateway} // Use the imported image
                      alt="locked"
                      style={{ width: "100%", height: "100%", borderRadius: "20px",marginBottom: "150px"}} // Round the corners of the image
                    />
                    <Typography variant="h6" style={{ textAlign: "center", fontSize: "30px", marginBottom: "4px",marginTop: "-200px", fontFamily: "ubuntu" }}>
                      Login{/* Change the text */}
                    </Typography>
                  </div>
            </Button>
        </div>
    );
};
