import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";
import { loginRequest } from "../authConfig";

export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect({
            ...loginRequest,
            redirectUri: process.env.REACT_APP_POPUP_REDIRECT_URI,
        });
    };

    return (
        <div>
            <Button
                onClick={handleLogin}
                color="inherit"
                variant="outlined"
                style={{ margin: '15px' }}
            >
                Login
            </Button>
        </div>
    );
};
