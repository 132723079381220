import React, { useState, useEffect, formEmbedCode, useHistory } from 'react';
import { useMsal, useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import {
  Button,
  Paper,
  Typography,
  Grid,

} from '@mui/material';
import { Link as RouterLink, Navigate, Link } from "react-router-dom";
import houseImage from "../images/house.png";
import Footer from "../ui-components/Footer";
import thumbs from "../images/thumbs_up.png";
import supportImage from "../images/support.png";

function SupportMessage() {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <Typography variant="h3" style={{ fontSize: "40px", textAlign: "left", fontWeight: 'bold', fontFamily: "ubuntu", marginBottom: "10px" }}>

        This process may take up to an hour to complete.

      </Typography>
      <Typography variant="body1" style={{ fontSize: "20px", textAlign: "left", fontWeight: 'normal', fontFamily: "ubuntu" ,  marginTop: '20px', marginTop: '50px'}}>
      We have sent your Journal Upload to Oracle Fusion, please note: the posting process in Fusion is normally completed within a few minutes but may take up to an hour. 
      </Typography>
    </div>
  );
}

function UnderlinedText({ children }) {
  const styles = {
    textUnderline: {
      position: 'relative',
    },
    textUnderlineAfter: {
      content: '',
      position: 'absolute',
      left: 0,
      bottom: -6,
      height: '6px', // Adjust thickness
      width: '100%',
      backgroundColor: '#DE772E',
    },
  };

  return <span style={styles.textUnderline}>{children}<span style={styles.textUnderlineAfter}></span></span>;
}

export function Done() {
  const [message, setMessage] = useState('');

  return (
    <>
      <AuthenticatedTemplate>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", maxWidth: "1200px" }}>
          <div style={{
            textAlign: "left",
            fontSize: "65px",
            fontFamily: "Ubuntu",
            fontWeight: "bold",
            marginTop: "100px",
            marginBottom: "10px",
            width: "100%",
            maxWidth: "1200px"
          }}>

            <Typography variant="h1" style={{ fontSize: "60px", textAlign: "left", fontWeight: 'bold', fontFamily: "ubuntu", marginBottom: "10px" }}>

              <UnderlinedText>Sorted!</UnderlinedText>
              <img src={thumbs} alt="monocle" style={{ marginLeft: "10px", width: "80px", height: "80px" }} />


            </Typography>
            <Typography variant="h1" style={{ fontSize: "60px", textAlign: "left", fontWeight: 'bold', fontFamily: "ubuntu", marginBottom: "10px" }}>

              <UnderlinedText>Your journal is on the move...</UnderlinedText>

            </Typography>
          </div>
          <Paper elevation={3} style={{ marginTop: '50px', border: '6px solid #DE772E', borderRadius: '10px', padding: '3rem', textAlign: 'center', marginBotton: '50px', width: "100%", maxWidth: "1100px" }}>
            <SupportMessage />
            <div>

            <Button
                    component={RouterLink}
                    to="/support"
                    variant="contained"
                    style={{
                      width: "200px",
                      height: "200px",
                      borderRadius: "20px",
                      marginBottom: "16px",
                      transition: "transform 0.2s",
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.transform = "scale(1.05)";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                      <img
                        src={supportImage} // Use the imported image
                        alt="Support"
                        style={{ width: "100%", height: "100%", borderRadius: "20px" }} // Round the corners of the image
                      />
                      <Typography variant="h6" style={{ textAlign: "center", fontSize: "18px", marginBottom: "4px", fontFamily: "ubuntu" }}>
                        Support
                      </Typography>
                    </div>
                  </Button>
                  </div>


          </Paper>


          <div style={{ display: 'flex', justifyContent: 'center', marginTop: "70px" }}>
            <Grid container spacing={4} justifyContent="center">
              <Grid item>
                <Button
                  component={RouterLink}
                  to="/"
                  variant="contained"
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "20px", // Rounded corners
                    marginBottom: "16px", // Add margin to separate the button and text
                    transition: "transform 0.2s", // Add hover effect
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.transform = "scale(1.15)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <img
                      src={houseImage} // Use the imported image
                      alt="Journal"
                      style={{ width: "100%", height: "100%", borderRadius: "20px" }} // Round the corners of the image
                    />
                    <Typography variant="h6" style={{ textAlign: "center", fontSize: "14px", marginBottom: "4px" }}>
                      Go Home {/* Change the text */}
                    </Typography>
                  </div>
                </Button>
              </Grid>
            </Grid>
          </div>
          <footer style={{ fontFamily: "Ubuntu", fontSize: '18px', textAlign: 'center', marginTop: '2rem' }}>
            <p>&copy; 2023-{new Date().getFullYear()}  Wincanton PLC - Finance Development Team</p>
            <p>{process.env.REACT_APP_JL_ENV}</p>
          </footer>
        </div>
        <div style={{

          width: '100%',
        }}>
          <Footer />
        </div>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div> // naviage users to homepage
          <Navigate to="/" replace={true} />
        </div>
      </UnauthenticatedTemplate>
    </>
  );
}
