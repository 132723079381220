import React, { useState, useEffect } from 'react';
import { useMsal, useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import {
  Button,
  Typography,
  CircularProgress,
  Grid,
  Paper,
} from '@mui/material';
import { journalAPIinstance } from '../utils/CorsEndpoints';
import { Link as RouterLink } from "react-router-dom";
import journalImage from "../images/journal.png";
import WelcomeNameHeader from "../ui-components/WelcomeNameHeader";
import Footer from "../ui-components/Footer";
import supportImage from "../images/support.png";
import Modal from 'react-modal'
import wave from "../images/wave.png";
import monocle from "../images/face_with_monocle.png";
import gateway from "../images/gateway-blue.png";
import { SignInButton } from "../ui-components/SignInButtonHomePage";
import bowser from 'bowser'
import '../styles/style.css';
import { loginRequest } from "../authConfig";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


function modalLoading() {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <img src={gateway} alt="monocle" style={{ marginLeft: "0px", width: "200px", height: "200px" }} />
      <Typography variant="h2" style={{ textAlign: "center", fontWeight: 'bold', fontFamily: "ubuntu", marginBottom: "10px" }}>

        Welcome to Gateway

      </Typography>
    </div>
  );
}

function modalFailed() {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <img src={monocle} alt="monocle" style={{ marginLeft: "0px", width: "200px", height: "200px" }} />
      <Typography variant="h2" style={{ textAlign: "center", fontWeight: 'bold', fontFamily: "ubuntu", marginBottom: "10px" }}>

        Ooops, Something Went wrong.

      </Typography>
    </div>
  );
}

function UnderlinedText({ children }) {
  const styles = {
    textUnderline: {
      position: 'relative',
    },
    textUnderlineAfter: {
      content: '',
      position: 'absolute',
      left: 0,
      bottom: -6,
      height: '6px',
      width: '100%',
      backgroundColor: '#DE772E',
    },
  };

  return <span style={styles.textUnderline}>{children}<span style={styles.textUnderlineAfter}></span></span>;
}


export function Home() {
  const [isAllowed, setIsAllowed] = useState(false);
  const [message, setMessage] = useState('');
  const isAuthenticated = useIsAuthenticated();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [modalMessage, setModalMessage] = useState('Loading...');
  const [isLoading, setIsLoading] = useState(false);
  const [browserName, setBrowserName] = useState('')
  const { instance, accounts } = useMsal();
  const [showUnsupportedAlert, setShowUnsupportedAlert] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const isFutureDate = (expiryDateString) => {
    const expiryDate = new Date(expiryDateString);
    const now = new Date();
    return expiryDate > now;
  };



  const makeApiCall = async () => {
    try {
      const response = await journalAPIinstance.get(process.env.REACT_APP_JL_SETTINGS_URL);
      if (response.data && isFutureDate(response.data.Expires)) {
        setAlertSeverity(response.data.Severity);
        setAlertMessage(response.data.Contents);
        setOpenAlert(true); // Show the alert 
        console.error(response);
      }
    } catch (error) {
      console.error("API Error:", error);
      setAlertSeverity('error');
      setAlertMessage('An error occurred while fetching data.');
      setOpenAlert(true);
    }
  };


  const getAccessToken = async () => {
    setIsLoading(true)
    try {
      const accessToken = await instance.acquireTokenSilent({
        scopes: ["user.read", "openid"],
        account: accounts[0]
      });
      console.log("Acquired Access Token:");
      console.log("Token Type:", typeof accessToken);
      return accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // User interaction is needed, use either popup or redirect
        try {
          await instance.loginRedirect({
            ...loginRequest,
            redirectUri: process.env.REACT_APP_POPUP_REDIRECT_URI,
          });

          // Login successful, now get the access token
          const accessToken = await instance.acquireTokenSilent({
            scopes: ["user.read", "openid"],
            account: accounts[0]
          });
          return accessToken;
        } catch (popupError) {
          console.error("Error acquiring token via popup:", popupError);
          // Handle errors or perhaps try redirect if popup fails
        }
      } else {
        console.error("Error getting access token:", error);
        await instance.loginRedirect({
          ...loginRequest,
          redirectUri: process.env.REACT_APP_POPUP_REDIRECT_URI,
        });

        // Login successful, now get the access token
        const accessToken = await instance.acquireTokenSilent({
          scopes: ["user.read", "openid"],
          account: accounts[0]
        });
        return accessToken;
      }
    }
  }
  useEffect(() => {
    const browser = bowser.getParser(window.navigator.userAgent);
    setBrowserName(browser.getBrowserName());

    // Redirect if the user is NOT on Chrome or Firefox
    if (browser.getBrowserName() !== "Chrome" && browser.getBrowserName() !== "Firefox") {
      window.location.href = "https://setup.wincanton-ssc.co.uk";
    }
  }, []);



  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '5px',
      outline: 'none',
      padding: '20px',
      marginTop: "50px"
    }
  };

  const buttonCheck = async (userEmail) => {
    try {
      makeApiCall();
      const accessToken = await getAccessToken();

      // Set the initial loading message
      setIsModalOpen(true);
      setIsLoading(true)
      setModalMessage('Loading...');

      // Introduce the delay
      await new Promise(resolve => setTimeout(resolve, 2000));
      const formData = new FormData();
      formData.append('email', userEmail);
      formData.append('accessToken', accessToken.accessToken); // Add the access token to the form data

      //       console.log("Form Data Entries:", formData.entries());
      //       for (const pair of formData.entries()) {
      //           console.log(pair[0], ':', pair[1]);
      // }
      const response = await journalAPIinstance.post(
        process.env.REACT_APP_JL_ROLE_URL,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data' // Keep this header 
          },
        },
      );


      if ('DENIED' === response.data.ACCESS) {
        setIsAllowed(false);
        setIsLoading(false);
        setMessage('REF-002: You do not have access to any apps on this platform. If you believe this is an error, please contact Central Finance');
        setModalMessage('REF-002: You do not have access to any apps on this platform. If you believe this is an error, please contact Central Finance');
      } else {
        setIsAllowed(true);
        setMessage('');
        setIsModalOpen(false);

      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error checking role access:", error);


      if (error.response && error.response.status === 401) {
        setMessage('REF-004: Unauthorized. Please try logging in again.');
      } else {
        setMessage('REF-003: Unable to check role access.');
        setModalMessage('REF-003: Unable to check role access. Please contact Central Finance');
      }

      setIsAllowed(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      const userEmail = accounts[0].username;
      buttonCheck(userEmail).then(() => {
      });
    }
  }, [isAuthenticated, accounts]);



  return (
    <>
      <AuthenticatedTemplate>
        return (

        <div className="page-container" style={{
          marginTop: "80px",


        }}>

          <Collapse in={openAlert} >
            <Alert variant="filled" severity={alertSeverity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {alertMessage}
            </Alert>
          </Collapse>

          <div class="image-container" style={{
            textAlign: "left",
            fontSize: "65px",
            fontFamily: "Ubuntu",
            fontWeight: "bold",
            marginTop: "20px",
            marginBottom: "10px",
          }}>

            <div style={{ display: 'flex', alignItems: 'center' }}>

              <WelcomeNameHeader />
              <img src={wave} alt="monocle" style={{ marginLeft: "10px", width: "80px", height: "80px" }} />

            </div>
          </div>


          <div style={{
            fontFamily: "Ubuntu",
            fontWeight: "bold",
            marginBottom: "25px",
          }}>
            <Typography variant="h1" style={{ fontSize: "50px", textAlign: "left", fontWeight: 'bold', fontFamily: "ubuntu", marginBottom: "10px" }}>

              Welcome to Gateway


            </Typography>
            <div style={{ fontSize: "22px", fontFamily: "Ubuntu", fontWeight: "bold", marginBottom: "15px" }}>

              <Typography variant="h1" style={{ fontSize: "20px", textAlign: "left", fontWeight: 'normal', fontFamily: "ubuntu" }}>

                <p>Gateway is your portal to finance apps and automations.</p>
                <p>Further apps will appear over the coming months, but for now choose an app from those currently available and get started.</p>

              </Typography>
            </div>
          </div>

          <Paper
            elevation={3}
            style={{
              border: '6px solid #DE772E',
              borderRadius: '10px',
              padding: '3rem',
              textAlign: 'center',
              marginTop: '10x',
            }}>

            <div style={{ fontSize: "24px", fontFamily: "Ubuntu", fontWeight: "bold", marginBottom: "40px" }}>
              <Typography variant="h1" style={{ fontSize: "30px", textAlign: "center", fontWeight: 'bold', fontFamily: "ubuntu", marginBottom: "10px" }}>

                Choose an app to get started

              </Typography>
            </div>

            <div className="app-container" >
              <Grid container spacing={4} justifyContent="center">
                {isAllowed && (
                  <Grid item justifyContent={"center"}>
                    <Button
                      component={RouterLink}
                      to="/upload"
                      variant="contained"
                      style={{
                        width: "200px",
                        height: "200px",
                        borderRadius: "20px", // Rounded corners 
                        transition: "transform 0.2s", // Add hover effect
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)";
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <img
                          src={journalImage} // Use the imported image
                          alt="Journal"
                          style={{ width: "100%", height: "100%", borderRadius: "20px" }} // Round the corners of the image
                        />
                        <Typography variant="h6" style={{ textAlign: "center", fontSize: "18px", marginBottom: "4px", fontFamily: "ubuntu" }}>
                          Journal Upload {/* Change the text */}
                        </Typography>
                      </div>
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    component={RouterLink}
                    to="/support"
                    variant="contained"
                    style={{
                      width: "200px",
                      height: "200px",
                      borderRadius: "20px",
                      marginBottom: "16px",
                      transition: "transform 0.2s",
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.transform = "scale(1.05)";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                      <img
                        src={supportImage} // Use the imported image
                        alt="Support"
                        style={{ width: "100%", height: "100%", borderRadius: "20px" }} // Round the corners of the image
                      />
                      <Typography variant="h6" style={{ textAlign: "center", fontSize: "18px", marginBottom: "4px", fontFamily: "ubuntu" }}>
                        Support
                      </Typography>
                    </div>
                  </Button>
                </Grid>
              </Grid>
            </div>

          </Paper>

        </div>
        <div style={{

          width: '100%',
        }}>
          <Footer />
        </div>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          style={customStyles}
          contentLabel="Access Status Modal"
          ariaHideApp={false} // Important for screen readers
        >
          <div style={{ textAlign: 'center', fontFamily: "ubuntu" }}>
            <h1
              style={{ fontSize: "80px" }}
            >
              {isLoading ? modalLoading() : modalFailed()}
            </h1>
            {isLoading && <CircularProgress size="6rem" />}
            <p
              style={{ fontSize: "50px" }}
            >{modalMessage}</p>
            {!isLoading && (
              <Button
                component={RouterLink}
                to="/support"
                variant="contained"
                style={{
                  width: "200px",
                  height: "200px",
                  borderRadius: "20px",
                  marginBottom: "16px",
                  transition: "transform 0.2s",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                }}
              >
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                  <img
                    src={supportImage} // Use the imported image
                    alt="Support"
                    style={{ width: "100%", height: "100%", borderRadius: "20px" }} // Round the corners of the image
                  />
                  <Typography variant="h6" style={{ textAlign: "center", fontSize: "18px", marginBottom: "4px", fontFamily: "ubuntu" }}>
                    Support
                  </Typography>
                </div>
              </Button>
            )}

          </div>
        </Modal>

      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Grid Grid container spacing={4} justifyContent="center" style={{ gridTemplateColumns: "75% 25%", columnGap: "80px" }}>
          <Grid Item style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "150px" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", maxWidth: "1600px" }}>
              <div style={{
                textAlign: "left",
                fontSize: "65px",
                fontFamily: "Ubuntu",
                fontWeight: "bold",
                marginTop: "80px",
                marginBottom: "10px",
                width: "100%",
                maxWidth: "1200px",
                lineHeight: "0.5",
              }}>

                <p style={{ fontSize: "80px" }}><UnderlinedText>Welcome to Gateway</UnderlinedText></p>
                <p style={{ fontSize: "50px" }}>The Finance Workspace</p>

                <Typography variant="h1" style={{ fontSize: "20px", fontWeight: 'normal', fontFamily: "ubuntu" }}>

                  <p style={{ fontSize: "30px" }}>Login to get started...</p>


                </Typography>
              </div>
            </div>
          </Grid>
          <Grid Item style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "120px" }}>
            <div>

              <SignInButton style={{ marginTop: "50px" }} />

            </div>
          </Grid>
        </Grid>
        <div style={{

          width: '100%',
          marginTop: "10%",
        }}>
          <Footer />
        </div>

      </UnauthenticatedTemplate>
    </>
  );
}