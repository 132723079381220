import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import WelcomeName from "./WelcomeName";
import SignInSignOutButton from "./SignInSignOutButton";
import { Link as RouterLink } from "react-router-dom";
import GatewayLogo from "../images/gateway-logo.png";


const NavBar = () => {

  return (
    <div sx={{ flexGrow: 1 }}>
      <AppBar sx={{ width: "100%" }}>
        <Toolbar>
          <Typography sx={{ flexGrow: 1 }}>
            <Link component={RouterLink} to="/" color="inherit" variant="p">
              <img src={GatewayLogo} alt="BPS Gateway" style={{ width: 'auto', height: '40px' }} />
            </Link>
          </Typography>
          <WelcomeName />
          <SignInSignOutButton />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavBar;

