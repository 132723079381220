import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Paper,
  Typography,
  CssBaseline,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from '@mui/material';
import { useMsal, useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { journalAPIinstance } from '../utils/CorsEndpoints';
import Modal from 'react-modal';
import bannerImage from "../images/banner.png";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

import { useNavigate } from 'react-router-dom';
import { Link as RouterLink, Navigate } from "react-router-dom";
import monocle from "../images/face_with_monocle.png";
import prohibited from "../images/prohibited.png";
import journalImage from "../images/journal.png";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import houseImage from "../images/house.png";
import '../styles/style.css';
import Footer from "../ui-components/Footer";

//import mymodal from '../ui-components/modal';


// Create a new component for the welcome message
function WelcomeMessage() {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <Typography variant="h4" style={{ lineHeight: "2", fontSize: "30px", textAlign: "Center", fontWeight: 'bold', fontFamily: "ubuntu" }}>
        Follow these steps to get started:
      </Typography >
      <Typography variant="h1" style={{ lineHeight: "2", fontSize: "20px", textAlign: "left", fontWeight: 'normal', fontFamily: "ubuntu" }}>
        <ol>
          <li>Select "Choose File" and select the file you wish to upload.</li>
          <li>The data in your Journal Upload file will be validated by Nebula</li>
          <li>Check the data in the table to ensure it is correct.</li>
          <li>Click the "Submit" button to complete the upload.</li>
        </ol>
      </Typography>
    </div>
  );
}

function modalLoading() {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <img src={journalImage} alt="monocle" style={{ marginLeft: "0px", width: "200px", height: "200px" }} />
      <Typography variant="h2" style={{ textAlign: "center", fontWeight: 'bold', fontFamily: "ubuntu", marginBottom: "10px" }}>

        Journal Upload Application

      </Typography>
    </div>
  );
}

function modalFailed() {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <img src={monocle} alt="monocle" style={{ marginLeft: "0px", width: "200px", height: "200px" }} />
      <Typography variant="h2" style={{ textAlign: "center", fontWeight: 'bold', fontFamily: "ubuntu", marginBottom: "10px" }}>

        Ooops, Something Went wrong.

      </Typography>
    </div>
  );
}

function UnderlinedText({ children }) {
  const styles = {
    textUnderline: {
      position: 'relative',
    },
    textUnderlineAfter: {
      content: '',
      position: 'absolute',
      left: 0,
      bottom: -6,
      height: '6px',
      width: '100%',
      backgroundColor: '#DE772E',
    },
  };

  return <span style={styles.textUnderline}>{children}<span style={styles.textUnderlineAfter}></span></span>;
}


export function FileUpload() {
  let userEmail;
  const [uploadMessage, setUploadMessage] = useState('');
  const [uploadPeriod, setUploadPeriod] = useState('');
  const [uploading, setUploading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [responseError, setResponseError] = useState("Unknown Error");
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [showButton, setShowButton]  = useState(false);
  const [emailUser, setEmailUser]  = useState("email undefined");
  const [pageLink, setPageLink] = useState('')
  const getAccessToken = async () => {
    try {
      const accessToken = await instance.acquireTokenSilent({
        scopes: ["user.read", "openid"], // The permissions you need
        account: accounts[0] // The currently logged in user's account
      });
      console.log("Acquired Access Token:"); 
      console.log("Token Type:", typeof accessToken); 
      return accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenPopup({ 
          scopes: ["user.read", "openid"]
        }); 
      } else {
        console.error("Error getting access token:", error);
      }
    }
  }
  const navigate = useNavigate();
  const [isAllowed, setIsAllowed] = useState(false);
  const [isModalOpenFull, setIsModalOpenFull] = useState(true);
  const [modalMessage, setModalMessage] = useState('Loading...');
  const [isLoading, setIsLoading] = useState(false);


  const customStyles = { //modal_style
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '5px',
      outline: 'none',
      padding: '20px',
      marginTop: "50px"
    }
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  var ModalContent = () => {
    return (
      <div style={{ marginBottom: '1rem' }}>
          <Grid container justifyContent="center" alignItems="center" direction="column">
    <Grid item sx={{ mb: 7, mt: 3}}> 
      <img src={prohibited} alt="monocle" style={{ width: "100px", height: "100px" }} />
    </Grid>
    <Grid item>
      <Typography variant="h2" style={{ fontSize: "50px", fontWeight: 'bold', fontFamily: "ubuntu" }}>
        Unable to proceed!
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="h5" style={{ textAlign:"center", fontSize: "30px", fontWeight: 'normal', fontFamily: "ubuntu" }}>
        Reason: {responseError}
        {pageLink}
      </Typography>
    </Grid>
  </Grid>

        <Button
          className="modal-button"
          style={{ 
            fontSize: "20px", 
            backgroundColor: '#041E42', 
            color: 'white',
            position: 'absolute',  /* Added for positioning */
            bottom: '10px',        /* Adjust as needed */ 
            right: '10px'          /* Adjust as needed */
            }}
          onMouseOver={(e) => { e.currentTarget.style.transform = "scale(1.05)"; }}
          onMouseOut={(e) => { e.currentTarget.style.transform = "scale(1)"; }}
          onClick={() => {
            setIsModalOpen(false); // Close the modal 
            window.location.reload(); // Refresh the page
          }}
        >
          Close
        </Button>

      </div>

    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  // Obtain the user's email address from MSAL



  // set up the rolecheck function
  const roleCheck = async (userEmail) => {
    try {
      setIsModalOpenFull(true);
      setIsLoading(true)
      setModalMessage('Loading...');

      // New Promise for timeout management 
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error('Role check timeout exceeded')), 60000); // 1 minute timeout
      });

      const formData = new FormData();
      const accessToken = await getAccessToken(); 
      formData.append('email', userEmail);
      formData.append('accessToken', accessToken.accessToken); 

      // Race between timeout and API request
      Promise.race([
        journalAPIinstance.post(
          process.env.REACT_APP_JL_ROLE_URL,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          },
        ),
        timeoutPromise
      ])
      .then(response => {
          // Check if the response is denied
          if ('DENIED' == response.data.ACCESS) {
            setIsAllowed(false);
            setIsLoading(false);
            console.error('ACCESS DENIED');
            window.location.href = process.env.REACT_APP_JL_REDIRECT_URL;
          } else {
            setIsModalOpenFull(false);
            setIsAllowed(true);
            // Process successful upload
          }
      })
      .catch(error => {
          setIsAllowed(false);
          setIsLoading(false);
          // Handle timeout separately if desired
          if (error.message === 'Role check timeout exceeded') {
            setUploadMessage('Error: Role check timed out');
          } else {  
            setUploadMessage('Error: Unable to check role');
          }
          console.error(error)
          window.location.href = process.env.REACT_APP_JL_REDIRECT_URL;
      });
    } catch (error) {
      setIsAllowed(false);
      setIsLoading(false);
      setUploadMessage('Error: Unable to check role');
      console.error(error)
      window.location.href = process.env.REACT_APP_JL_REDIRECT_URL;
    }
  };

  // Call roleCheck
  useEffect(() => {
    if (isAuthenticated) {
      const userEmail = accounts[0].username;
      setEmailUser(userEmail);
      roleCheck(userEmail);
    }
  }, [isAuthenticated, accounts]);





  const handleUpload = () => {
    console.log("handleUpload called");
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('email', emailUser);
      setUploading(true);

      // Timeout Setup
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error('Upload timeout exceeded')), 60000); // 1 minute example
      });

      // API Call with Timeout
      Promise.race([
        journalAPIinstance.post(
          process.env.REACT_APP_JL_ACTION_URL,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        ),
        timeoutPromise
      ])
      .then(response => {
        // Check for errors
        if ('ERROR' in response.data) {
          console.error(response.data.ERROR)
          if (response.data.ERROR === 'You uploaded an outdated excel document. Please use the latest version.') {
            setPageLink(<p>Please find the latest file version <RouterLink to={process.env.REACT_APP_FILES}>here</RouterLink></p>); 
        } else { // Removed the extra semicolon
            setPageLink("");
        }
          console.error('Error uploading file:', response.data.ERROR);
          setSelectedFile(null); 
          setResponseError(response.data.ERROR);
          setUploading(false);
          setIsModalOpen(true);
        } else {
          // Successful upload
          setUploadMessage(response.data.message);
          setUploadPeriod(response.data.period);
          setUploading(false);
          setTableData(response.data.data);
          setShowButton(true);
        }
      })
      .catch(error => {
        // Handle timeout or API errors
        if (error.message === 'Upload timeout exceeded') {
          setResponseError('Error: Validation timed out');
        }
        console.error(error);
        setUploading(false);
        setIsModalOpen(true);
      });
    }
  };


  useEffect(() => {
    if (selectedFile) {
      handleUpload();
    }
  }, [selectedFile]);

  const handleSubmit = () => {
    if (selectedFile) {
      const formData = new FormData();
      setSubmitting(true);
      formData.append('file', selectedFile);
      formData.append('email', emailUser);
  
      // Timeout setup
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error('Submit timeout exceeded')), 60000); // 120 seconds
      });
  
      // Race the upload request against the timeout
      Promise.race([
        journalAPIinstance.post(process.env.REACT_APP_JL_UPLOAD_URL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }),
        timeoutPromise
      ])
      .then(response => {
        console.log('File submitted successfully:', response.data);
        setUploadMessage(response.data.message);
        setTableData([]);
        setSelectedFile(null);
        navigate('/done'); // redirect on success
      })
      .catch(error => {
        console.error('Error submitting file:', error);
  
        // Check for timeout
        if (error.message === 'Submit timeout exceeded') {
          setResponseError('Error: Submission failed due to timing out. Please check your internet connection.');
        }
        setIsModalOpen(true);
        setSelectedFile(null);
      })
      .finally(() => {
        setSubmitting(false); // Ensure submitting state is reset
        setUploading(false);
        setTableData([])
      });
    }
  };
  



  const columnHeaders = [
    "Company",
    "Operating Centre",
    "Customer Contract",
    "Account",
    "Analysis",
    "Intercompany",
    "Spare",
    "Currency",
    "Entered Debit",
    "Entered Credit",
    "Conversion Date",
    "Conversion Rate Type",
    "Conversion Rate",
    "Account Debit",
    "Account Credit",
    "Line Description"
  ]; // Define the desired column header order



  //
  //  TEST LOCALLY WITH PERIOD DATE AS ENV TO MAKE SURE
  //  ADD MODAL BUTTON AND CLICK TO TRIGGER MODAL TO TEST
  // THEN LEARN HOW TO CURL TO A FRONTEND
  //
  return (
    <>
      <AuthenticatedTemplate>


        <div>
          <div className="page-container" style={{
            marginTop: "110px",

          }}>
            <div style={{
              textAlign: "left",
              fontSize: "50px",
              fontFamily: "Ubuntu",
              fontWeight: "bold",
              marginBottom: "10px",

            }}>

              <div style={{ display: 'flex', alignItems: 'center' }}>

                <UnderlinedText>Journal Upload Application</UnderlinedText>


              </div>



              <div style={{ fontFamily: "Ubuntu", fontWeight: "bold", marginBottom: "25px" }}>

                <div style={{ fontSize: "22px", fontFamily: "Ubuntu", fontWeight: "bold", marginBottom: "15px", marginTop: "25px" }}>

                  <Typography variant="h1" style={{ fontSize: "20px", textAlign: "left", fontWeight: 'normal', fontFamily: "ubuntu" }}>

                    <p>The Journal Upload Application enables the creation of Journal entries in Oracle Fusion.</p>
                    <p>You can find support by clicking the button below.</p>

                  </Typography>
                </div>
              </div>

              <Paper
                elevation={3}
                style={{
                  border: '6px solid #DE772E',
                  borderRadius: '10px',
                  padding: '3rem',
                  textAlign: 'center',
                  marginTop: '10x',
                }}>
                <Grid container spacing={4} justifyContent="center">
                  <Grid item justifyContent={"left"}>
                    <WelcomeMessage />
                  </Grid>
                  <Grid item justifyContent={"left"} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "40px" }}>
                    <Paper
                      elevation={2}
                      style={{
                        border: '3px solid #041E42',
                        borderRadius: '10px',
                        paddingLeft: "40px",
                        paddingRight: "40px",
                        padding: '10px',
                        textAlign: 'center',
                        marginTop: '10px'
                      }}>
                      <div style={{ fontSize: "22px", fontFamily: "Ubuntu", fontWeight: "bold", marginBottom: "15px", marginTop: "25px" }}>

                        <Typography variant="h1" style={{ fontSize: "15px", textAlign: "Center", fontWeight: 'normal', fontFamily: "ubuntu" }}>

                          <p>Please Note: Only Excel files (XLSX) are accepted.</p>

                        </Typography>
                      </div>
                      <Button
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        onClick={(event) => event.stopPropagation()}

                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "scale(1.05)";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                        }}
                      >
                        Choose File
                        <input
                          type="file"
                          style={{ display: 'none' }}
                          onChange={(e) => {
                            setSelectedFile(e.target.files[0]);
                            handleUpload(); // Trigger handleUpload on file selection
 
                          }}
                        />
                      </Button>
                    </Paper>

                    <div style={{ marginTop: '1rem' }}>
                      {uploading && <CircularProgress />}
                    </div>
                  </Grid>
                </Grid>

              </Paper>

              <Modal isOpen={isModalOpen}
                contentLabel="Error Message"
                style={{
                  content: {
                    width: '50%',
                    height: '50%',
                    left: '25%',
                    top: '25%'
                  }

                }}
                className="modal-with-border">
                <ModalContent />
              </Modal>
              {tableData.length > 0 && (


                <Paper
                  elevation={2}
                  style={{
                    border: '3px solid #041E42',
                    borderRadius: '10px',
                    textAlign: 'center',
                    marginTop: "30px",
                  }}>
                  <div style={{ fontSize: "22px", fontFamily: "Ubuntu", fontWeight: "bold", marginBottom: "15px", marginTop: "25px" }}>

                    <Typography variant="h1" style={{ fontSize: "30px", textAlign: "center", fontWeight: 'bold', fontFamily: "ubuntu" }}>

                      <p>Your Journal Upload.</p>
                      <p style={{ fontSize: '24px', fontWeight: 'bold' }}>{uploadPeriod}</p>

                    </Typography>
                  </div>
                  <TableContainer style={{ height: '600px', overflow: 'scroll' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {columnHeaders.map((header) => (
                            <TableCell key={header}>{header}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData.map((row, index) => (
                          <TableRow key={index}>
                            {columnHeaders.map((header) => (
                              <TableCell key={header}>{row[header]}</TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              )}
              <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                <p style={{ fontSize: '24px', fontWeight: 'bold' }}>{uploadMessage}</p>

                <div style={{ marginTop: '1rem' }}>
                  {submitting && <CircularProgress />}
                </div>
                {showButton && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      // Disable the button immediately using React state
                      setShowButton(false);
                      setSubmitting(true);
                      handleSubmit();
                    }}
                    style={{ fontSize: '18px', padding: '10px 30px' }}
                  >
                    Submit
                  </Button>
                )}

              </div>

              <div style={{ display: 'flex', justifyContent: 'center', marginTop: "70px", marginBottom: "100px"}}>
                <Grid container spacing={4} justifyContent="center">
                  <Grid item>
                    <Button
                      component={RouterLink}
                      to="/"
                      variant="contained"
                      style={{
                        width: "200px",
                        height: "200px",
                        borderRadius: "20px", // Rounded corners
                        marginBottom: "16px", // Add margin to separate the button and text
                        transition: "transform 0.2s", // Add hover effect
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)";
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <img
                          src={houseImage} // Use the imported image
                          alt="Journal"
                          style={{ width: "100%", height: "100%", borderRadius: "20px" }} // Round the corners of the image
                        />
                        <Typography variant="h6" style={{ textAlign: "center", fontSize: "14px", marginBottom: "4px" }}>
                          Go Home {/* Change the text */}
                        </Typography>
                      </div>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <div style={{

          width: '100%',
        }}>
          <Footer />
        </div>


        <Modal
          isOpen={isModalOpenFull}
          onRequestClose={() => setIsModalOpenFull(false)}
          style={customStyles}
          contentLabel="Access Status Modal"
          ariaHideApp={false} // Important for screen readers
        >
          <div style={{ textAlign: 'center', fontFamily: "ubuntu" }}>
            <h1
              style={{ fontSize: "80px" }}
            >
              {modalLoading()}
            </h1>
            {isLoading && <CircularProgress size="6rem" />}
            <p
              style={{ fontSize: "50px" }}
            >{modalMessage}</p>

          </div>
        </Modal>

      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div> // naviage users to homepage
          <Navigate to="/" replace={true} />
        </div>
      </UnauthenticatedTemplate>
    </>
  );
}


