import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import wave from "../images/wave.png";

function UnderlinedText({ children }) {
    const styles = {
      textUnderline: {
        position: 'relative',
      },
      textUnderlineAfter: {
        content: '',
        position: 'absolute',
        left: 0,
        bottom: -6,
        height: '6px', // Adjust thickness
        width: '100%',
        backgroundColor: '#DE772E',
      },
    };
  
    return <span style={styles.textUnderline}>{children}<span style={styles.textUnderlineAfter}></span></span>; 
  }

const WelcomeNameHeader = () => {
    const { instance } = useMsal();
    const [name, setName] = useState(null);

    const activeAccount = instance.getActiveAccount();
    useEffect(() => {
        if (activeAccount) {
            setName(activeAccount.name.split(' ')[0]);
        } else {
            setName(null);
        }
    }, [activeAccount]);

    if (name) {
        return <Typography variant="h1" style={{ fontSize: "60px", textAlign: "left", fontWeight: 'bold', fontFamily: "ubuntu", marginBottom: "10px" }} > <UnderlinedText>Hello {name}</UnderlinedText> </Typography>;
    } else {
        return null;
    }
};

export default WelcomeNameHeader;