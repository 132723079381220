import React, { useState, useEffect, formEmbedCode, useHistory } from 'react';
import { useMsal, useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import {
  Button,
  Paper,
  Typography,
  Grid,

} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link as RouterLink, Navigate, Link } from "react-router-dom";
import houseImage from "../images/house.png";
import Footer from "../ui-components/Footer";

function SupportMessage() {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <Typography variant="h3" style={{ fontSize: "40px", textAlign: "left", fontWeight: 'bold', fontFamily: "ubuntu", marginBottom: "10px" }}>

        Your Support Options.

      </Typography>
      <Typography variant="body1" style={{ fontSize: "20px", textAlign: "left", fontWeight: 'bold', fontFamily: "ubuntu" }}>
      To get you to the right team as fast as possible, check out these resources:
      </Typography>
    </div>
  );
}

function sscMessage() {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <Typography variant="h6" style={{ textAlign: "left", fontWeight: 'bold', fontFamily: "ubuntu", marginBottom: "10px" }}>
        How to raie a ticket with Central Finance.
      </Typography>
      <Typography variant="body1" style={{ textAlign: "left", fontWeight: 'bold', fontFamily: "ubuntu" }}>
        Need help with a finance-related question or problem in ServiceNow? Follow these simple steps:
      </Typography>
      <ol style={{ fontSize: "16px", textAlign: 'left', fontFamily: "ubuntu" }}>
        <li>Go to the ServiceNow Portal.</li>
        <li>Find the Right Category.</li>
        <li>Open a New Ticket.</li>
        <li>Fill Out the Form.</li>
      </ol>
    </div>
  );
}

function UnderlinedText({ children }) {
  const styles = {
    textUnderline: {
      position: 'relative',
    },
    textUnderlineAfter: {
      content: '',
      position: 'absolute',
      left: 0,
      bottom: -6,
      height: '6px', // Adjust thickness
      width: '100%',
      backgroundColor: '#DE772E',
    },
  };

  return <span style={styles.textUnderline}>{children}<span style={styles.textUnderlineAfter}></span></span>;
}

export function Support() {
  const [message, setMessage] = useState('');

  return (
    <>
      <AuthenticatedTemplate>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", maxWidth: "1200px" }}>
          <div style={{
            textAlign: "left",
            fontSize: "65px",
            fontFamily: "Ubuntu",
            fontWeight: "bold",
            marginTop: "100px",
            marginBottom: "10px",
            width: "100%",
            maxWidth: "1200px"
          }}>

            <Typography variant="h1" style={{ fontSize: "50px", textAlign: "left", fontWeight: 'bold', fontFamily: "ubuntu", marginBottom: "10px" }}>

              <UnderlinedText>Need help or have a question?</UnderlinedText>


            </Typography>
            <Typography variant="h1" style={{ fontSize: "50px", textAlign: "left", fontWeight: 'bold', fontFamily: "ubuntu", marginBottom: "10px" }}>

              <UnderlinedText>You are in the right place!</UnderlinedText>

            </Typography>
          </div>
          <Paper elevation={3} style={{ border: '6px solid #DE772E', borderRadius: '10px', padding: '3rem', textAlign: 'center', marginTop: '50px', width: "100%", maxWidth: "1100px" }}>
            <SupportMessage />
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  style={{ fontSize: "25px", textAlign: "left", fontWeight: 'bold', fontFamily: "ubuntu" }}
                >
                  Central Finance Support
                </AccordionSummary>
                <AccordionDetails>

                  {sscMessage()}
                </AccordionDetails>
                <AccordionActions>
                  <Link to='https://wincanton.sharepoint.com/sites/change/SitePages/Get-help.aspx' target="_blank">
                    <Button
                      style={{ backgroundColor: '#041E42', color: 'white', marginRight: "2px" }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)";
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                      }}
                    >MyPlace Help</Button>
                  </Link>
                  <Link to='https://wincanton.service-now.com/sp?id=winc_rec_prod&sys_id=5b7ec6470fb031003970079ce1050e34' target="_blank">
                    <Button
                      style={{ backgroundColor: '#041E42', color: 'white', marginRight: "2px" }}
                      onMouseOver={(e) => { e.currentTarget.style.transform = "scale(1.05)"; }}
                      onMouseOut={(e) => { e.currentTarget.style.transform = "scale(1)"; }}
                    >Go TO ServiceNow</Button>
                  </Link>
                </AccordionActions>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  style={{ fontSize: "25px", textAlign: "left", fontWeight: 'bold', fontFamily: "ubuntu" }}
                >
                  Nebula Support
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ textAlign: "left", fontSize: "20px", fontFamily: "Ubuntu", fontWeight: "normal", marginTop: "2px", marginBottom: "16px" }}>
                    Please fill in the form below and one of the Nebula team will get back to you.
                    <p style={{ fontSize: '24px', fontWeight: 'bold' }}>{message}</p>
                  </div>
                  <iframe
                    width="100%"
                    height="800px"
                    src="https://forms.office.com/Pages/ResponsePage.aspx?id=g9lT4YzS4UKOfZfKjyHSKKq5S_aQZv5EupjrEgiX17pUMlpBTFI0N1o2SVlUWEhBV05CNkFQREVRTSQlQCN0PWcu&embed=true"
                    frameborder="0"
                    marginwidth="0"
                    marginheight="0"
                    style={{ border: "none", maxWidth: "100%", maxHeight: "100vh" }}
                    allowfullscreen
                    webkitallowfullscreen
                    mozallowfullscreen
                    msallowfullscreen
                  />
                </AccordionDetails>
              </Accordion>
            </div>


          </Paper>


          <div style={{ display: 'flex', justifyContent: 'center', marginTop: "70px" }}>
            <Grid container spacing={4} justifyContent="center">
              <Grid item>
                <Button
                  component={RouterLink}
                  to="/"
                  variant="contained"
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "20px", // Rounded corners
                    marginBottom: "16px", // Add margin to separate the button and text
                    transition: "transform 0.2s", // Add hover effect
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.transform = "scale(1.15)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <img
                      src={houseImage} // Use the imported image
                      alt="Journal"
                      style={{ width: "100%", height: "100%", borderRadius: "20px" }} // Round the corners of the image
                    />
                    <Typography variant="h6" style={{ textAlign: "center", fontSize: "14px", marginBottom: "4px" }}>
                      Go Home {/* Change the text */}
                    </Typography>
                  </div>
                </Button>
              </Grid>
            </Grid>
          </div>
          <footer style={{ fontFamily: "Ubuntu", fontSize: '18px', textAlign: 'center', marginTop: '2rem' }}>
            <p>&copy; 2023-{new Date().getFullYear()}  Wincanton PLC - Finance Development Team</p>
            <p>{process.env.REACT_APP_JL_ENV}</p>
          </footer>
        </div>
        <div style={{

          width: '100%',
        }}>
          <Footer />
        </div>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div> // naviage users to homepage
          <Navigate to="/" replace={true} />
        </div>
      </UnauthenticatedTemplate>
    </>
  );
}
