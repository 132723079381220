import React from "react";

import { Routes,
    Route, 
    useNavigate
 } from "react-router-dom";
// Material-UI imports
import Grid from "@mui/material/Grid";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";

// Sample app imports
import { PageLayout } from "./ui-components/PageLayout";
import { Home } from "./pages/Home";
import { Logout } from "./pages/Logout";
import { Done } from "./pages/Done";
import { Support } from "./pages/Support";
import { NavMenu } from './ui-components/navMenu'

// Class-based equivalents of "Profile" component
import { FileUpload } from "./pages/FileUpload";

import * as Sentry from "@sentry/react";
import './styles/style.css';

Sentry.init({
  dsn: "https://1d5879de4e198036f2779dfc860b1740@o4506003565772800.ingest.sentry.io/4506003574489088",
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.2,
  integrations: [
    new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true
      })],
  environment: process.env.sentryEnv,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);




function App({ pca }) {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);

    return (
        <MsalProvider instance={pca}>
            <navMenu />
            <PageLayout className="page-layout">
            <Grid container justifyContent="center" className="grid-container">
                    <Pages />
                </Grid>
            </PageLayout>
        </MsalProvider>
    );
}

function Pages() {
    return (
        <SentryRoutes>
            <Route path="/upload" element={<FileUpload />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/done" element={<Done />} />
            <Route path="/support" element={<Support />} />
            <Route path="/" element={<Home />} />
        </SentryRoutes>
    );
}

export default App;
