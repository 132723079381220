import * as React from 'react';
import { useMsal } from '@azure/msal-react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { Link as RouterLink, Link } from "react-router-dom";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// Custom hook for fetching profile photo
function useProfilePhoto() {
    const { instance, accounts } = useMsal();
    const [profilePhoto, setProfilePhoto] = React.useState(null);

    const getProfilePhoto = async () => {
        try {
            const graphResponse = await instance.acquireTokenSilent({
                scopes: ['User.Read'],
                account: accounts[0],
            }).then(tokenResponse => {
                return fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
                    headers: {
                        'Authorization': `Bearer ${tokenResponse.accessToken}`
                    }
                }).then(photoResponse => photoResponse.blob());
            });


            const photoBlob = await graphResponse;
            const reader = new FileReader();
            reader.readAsDataURL(photoBlob);
            reader.onloadend = () => setProfilePhoto(reader.result);
        } catch (error) {
            console.error("Error fetching profile picture:", error);
        }
    }

    React.useEffect(() => {
        getProfilePhoto();
    }, []);

    return profilePhoto;
}

export function NavMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { instance } = useMsal();
    const open = Boolean(anchorEl);
    const profilePhoto = useProfilePhoto();

    const handleLogout = async () => {

        localStorage.clear();

        const activeAccount = instance.getActiveAccount();
        if (activeAccount) {
            instance.removeAccount(activeAccount);
        }
        window.location.href = process.env.REACT_APP_JL_REDIRECT_URL

    }

    const handleMyAccountClick = () => {
        handleClose(); // Close the menu
        window.open("https://myaccount.microsoft.com/", "_blank");
      };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'right', textAlign: 'center', marginLeft: "20px" }}>
                <Tooltip title="Account settings">
                    <IconButton onClick={handleClick} /* .. rest */>
                        <Avatar
                            sx={{ width: 45, height: 45 }}
                            alt="User Profile Picture"
                            src={profilePhoto}
                        />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    {process.env.REACT_APP_JL_ENV}
                </MenuItem>
                <Divider />
                <MenuItem component={RouterLink} to="/support">
                    <ListItemIcon>
                        <SupportAgentIcon fontSize="small" />
                    </ListItemIcon>
                    Support
                </MenuItem>
                <MenuItem onClick={handleMyAccountClick}>
                <ListItemIcon>
                        <AccountCircleIcon fontSize="small" />
                    </ListItemIcon>
                    My Account
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}